<template>
  <div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data () {
    return {

    }
  },
  methods: {

  },
  mounted () {
    this.$router.push({
      name: 'Flixchat',
      params: {
        lang: 'de'
      }
    })
  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
